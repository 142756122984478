<template>
    <div>
        <el-row style="padding: 25px">
            <div style="width: 100%">
                <el-button style="width: 100px;float: right;margin-bottom: 10px;height: 30px;" type="primary"
                    @click="open('add', 0)">
                    添加分组
                </el-button>
            </div>
            <div style="width: 100%">
                <!-- default-expand-all 全部展开 -->
                <el-table :data="classifyManageDataList" style="width: 100%; margin-bottom: 20px" row-key="id" border
                    :header-cell-style="{ background: '#F2F2F2' }">
                    <el-table-column prop="name" label="分类名称" />
                    <el-table-column prop="tag" label="标签" />
                    <el-table-column prop="status" label="状态 " sortable align='center'>
                        <template #default="scope">
                            <el-tag v-if="scope.row.status == 1">已启用</el-tag>
                            <el-tag v-else>已禁用</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column prop="status" label="操作" align='center' width="200px">
                        <template #default="scope">
                            <el-button type="text" size="small" @click="open('add', scope.row.id)">新增子集</el-button>
                            <el-button type="text" size="small" @click="open('edit', scope.row)">编辑</el-button>
                            <el-button type="text" size="small" @click="del(scope.row)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </el-row>
        <!-- 添加/编辑分组弹窗 -->
        <el-dialog v-model="dialogFormAddEditVisible" :title="type == 'add' ? '添加分类' : '编辑分类'" width="450px" center
            :before-close="handleClose" :close-on-click-modal="false">
            <div style="margin-right: 10px;">
                <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" label-width="100px" class="demo-ruleForm"
                    :size="formSize" status-icon>
                    <el-form-item label="分类名称" prop="name">
                        <el-input v-model="ruleForm.name" placeholder="请输入分类名称" />
                    </el-form-item>
                </el-form>
            </div>
            <template #footer>
                <span class="dialog-footer">
                    <el-button v-if="type == 'add'" type="primary" @click="add">确定</el-button>
                    <el-button v-else type="primary" @click="edit">保存</el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import { getClassifylist, setclassifyadd, setclassifyupdate, setclassifydel } from '@/common/js/systemList.js'
export default {
    // 权限管理
    name: 'sellManageAuthority',
    // 组件参数 接收来自父组件的数据
    props: {},
    // import引入的组件需要注入到对象中才能使用
    components: {},
    data() {
        // 这里存放数据
        return {
            // 列表数据
            classifyManageDataList: [],
            // 是否显示添加/编辑分组弹窗
            dialogFormAddEditVisible: false,
            // 添加/编辑类型
            type: '',
            // 子集ID
            id: '',
            // 父级id
            pid: '',
            // 添加编辑分组
            ruleForm: {
                name: '',
            },
            // 分组规则
            rules: {
                name: [
                    { required: true, message: '请输入分类名称', trigger: 'blur' }
                ],
            },
        }
    },
    // 监听属性 类似于data概念
    computed: {},
    // 监控data中的数据变化
    watch: {
        dialogFormAddEditVisible() {
            if (this.dialogFormAddEditVisible == false) {
                this.ruleForm.name = ''
                this.id = ''
                this.pid = ''
                this.type = ''
            }
        }
    },
    // 方法集合
    methods: {
        // 初始化
        init() {
            getClassifylist().then(res => {
                if (res.code == 200) {
                    this.classifyManageDataList = res.result
                }
            })
        },
        // 打开编辑面板
        open(e, id) {
            if (e == 'add') {
                this.type = e
                this.id = id
                this.dialogFormAddEditVisible = true
            } else {
                this.type = e
                this.id = id.id
                this.pid = id.pid
                this.ruleForm.name = id.name
                this.dialogFormAddEditVisible = true
            }
        },
        // 新增分组
        add() {
            if (!this.ruleForm.name) {
                return this.$message.warning('请输入分类名称')
            }
            let dt = {
                pid: this.id,
                name: this.ruleForm.name
            }
            setclassifyadd(dt).then(res => {
                if (res.code == 200) {
                    this.init()
                    this.$message.success(res.msg)
                    this.dialogFormAddEditVisible = false
                }
            })
        },
        // 编辑分组
        edit() {
            let dt = {
                name: this.ruleForm.name,
                pid: this.pid,
                id: this.id,
            }
            setclassifyupdate(dt).then(res => {
                let { code, msg } = res
                if (code == 200) {
                    this.dialogFormAddEditVisible = false
                    this.$message.success(msg)
                    this.init()
                }
            })

        },
        // 删除分组
        del(item) {
            this.$confirm('此操作将永久删除该分类, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let dt = {
                    id: item.id
                }
                setclassifydel(dt).then(res => {
                    if (res.code == 200) {
                        this.$message.success(res.msg)
                        this.init()
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });

        },
    },
    // 生命周期 - 创建完成（可以访问当前this实例）
    created() {
        this.init()
    },
    // 生命周期 - 挂载完成（可以访问DOM元素）
    mounted() { },
    // 这里存放数据
    beforeCreate() { },
    // 生命周期 - 挂载之前 
    beforeMount() { },
    // 生命周期 - 更新之前 
    beforeUpdate() { },
    // 生命周期 - 更新之后 
    updated() { },
    // 如果页面有keep-alive缓存功能，这个函数会触发
    activated() { }
}
</script>
<style lang='scss' scoped>
// @import url(); 引入公共css类
::v-deep .el-dialog__body {
    padding-bottom: 0;
}
</style>